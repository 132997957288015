import React from "react"
import WorldMap from "../../components/worldmap"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Table from "../../components/visa-table"
import MobileVisas from "../../components/mobile-list"
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import AniLink from "gatsby-plugin-transition-link/AniLink"
import ScrollArrow from "../../components/scroll-arrow"


export default class StartupVisas extends React.Component {
  constructor(props){
      super(props);

      this.state = {
          showEcosystemsLink: false,
          showNextLink: false,
          showPrevLink: false
      }

      this.escFunction = this.escFunction.bind(this);
  }

  escFunction(event){
      // Esc key press
      if(event.keyCode === 27) {
          this.setState({
              showEcosystemsLink: true
          });

          setTimeout(() => {
              let linkRef = document.getElementById("esc-action");
              linkRef.click();
          }, 50)
      }

      // Left Arrow key press
      if(event.keyCode === 37) {
          this.setState({
              showPrevLink: true
          });

          setTimeout(() => {
              let prevLinkRef = document.getElementById("prev-action");
              prevLinkRef.click();
          }, 50)
      }

      // Right Arrow key press
      if(event.keyCode === 39) {
          this.setState({
              showNextLink: true
          });

          setTimeout(() => {
              let nextLinkRef = document.getElementById("next-action");
              nextLinkRef.click();
          }, 50)
      }
  }
  componentDidMount(){
      document.addEventListener("keydown", this.escFunction, false);
  }
  componentWillUnmount(){
      document.removeEventListener("keydown", this.escFunction, false);
  }

  render() {
      return (
  <Layout>
            {
            this.state.showEcosystemsLink &&
            <AniLink id="esc-action" fade to="/" duration={0.4}></AniLink >
        }
        {
            this.state.showPrevLink &&
            <AniLink id="prev-action" fade to="/startup-visas/netherlands" duration={0.4}></AniLink >
        }
        {
            this.state.showNextLink &&
            <AniLink id="next-action" fade to="/startup-visas/canada" duration={0.4}></AniLink >
        }
    <SEO title="Compare Startup Visas" />
    <div id="startup-visa-map">
      <WorldMap />
    <div id="arrow-container">    
      <ScrollArrow />
   </div>

    </div>

    <div id="visa-information-container">
      <div id="inner-information-container">
    <h1>Startup Visas</h1>
    <p className="some-description">
      Startup Visas have been a hot topic the past few years due to immigration and talent sourcing bottlenecks in ecosystems. While remote work has alleviated some of that pressure, founders still need to incorporate somewhere. There are 14 countries have startup visa programs—of those, only 7 are founder-friendly &amp; do not require amounts of capital invested — Canada, Estonia, Latvia, Lithuania, Finland, Denmark, and the Netherlands.
    <br/><br/>
    The other seven require a combination of graduate degrees, or a large amount of capital, making them less viable for lean entrepreneurs — the UK, Ireland, France, Portugal, Italy, Spain, and Chile.</p>

    {/* <h2>Startup Visas List</h2> */}
    <Table/>
    <MobileVisas/>

    <h3 id="legend">Startup Visa Table Legend</h3>
<div id="accordion-container">
<Accordion defaultActiveKey="0">
  <Card>
    <Accordion.Toggle as={Card.Header} eventKey="0">
    Degree Requirement
    </Accordion.Toggle>
    <Accordion.Collapse eventKey="0">
      <Card.Body>Not all startup visa options require degrees in order to qualify. Some such as Canada, Estonia, and Latvia require no degrees. Others, like England require a graduate level degree, and some require just undergraduate level degrees.</Card.Body>
    </Accordion.Collapse>
  </Card>
  <Card>
    <Accordion.Toggle as={Card.Header} eventKey="1">
      Visa Duration
    </Accordion.Toggle>
    <Accordion.Collapse eventKey="1">
      <Card.Body>This is how long you are allowed to stay on the startup visa you’re issued and whether it is permanent or not. Most visa types give you N years to establish your business and make it sustainably profitable at which point you qualify for permanent residence. Another important consideration is what happens if your business fails.</Card.Body>
    </Accordion.Collapse>
  </Card>
  <Card>
    <Accordion.Toggle as={Card.Header} eventKey="2">
      Bootstrappers
    </Accordion.Toggle>
    <Accordion.Collapse eventKey="2">
      <Card.Body>This is whether it is practical for bootstrappers, people building companies seeded with their own capital or revenue from the company itself are welcome to apply and the country has no large capital requirement.</Card.Body>
    </Accordion.Collapse>
  </Card>
  <Card>
    <Accordion.Toggle as={Card.Header} eventKey="3">
      Visa Fees
    </Accordion.Toggle>
    <Accordion.Collapse eventKey="3">
      <Card.Body>Processing fees that are payable at the point of your application. This is typically non-refundable regardless of outcome.</Card.Body>
    </Accordion.Collapse>
  </Card>
  <Card>
    <Accordion.Toggle as={Card.Header} eventKey="4">
      Personal Funds
    </Accordion.Toggle>
    <Accordion.Collapse eventKey="4">
      <Card.Body>These are the minimum funds you need to have in your bank account when applying for the startup visa in order to ensure that you can support yourself in terms of housing, food, and other expenses while you work on your business.</Card.Body>
    </Accordion.Collapse>
  </Card>
  <Card>
    <Accordion.Toggle as={Card.Header} eventKey="5">
      Minimum Investment
    </Accordion.Toggle>
    <Accordion.Collapse eventKey="5">
      <Card.Body>This specifies whether you need a minimum amount of investment in order for you and your company to qualify for the respective countries' startup visa programs.</Card.Body>
    </Accordion.Collapse>
  </Card>
  <Card>
    <Accordion.Toggle as={Card.Header} eventKey="6">
      Processing Time
    </Accordion.Toggle>
    <Accordion.Collapse eventKey="6">
      <Card.Body>Amount of time you can expect to take for your application to be processed and receive an outcome. Keep in mind that this varies on a per case basis.</Card.Body>
    </Accordion.Collapse>
  </Card>
</Accordion>
</div>
</div>
</div>
  </Layout>
)
      }}