import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
 
const Table = ({ children }) => {


    return (

        <div className="ReactTable">
          <div className="rt-table" role="grid">
            <div className="rt-thead -header" style={{minWidth: '800px'}}>
              <div className="rt-tr" role="row">
                <div className="rt-th -cursor-pointer" role="columnheader" tabIndex={-1} style={{flex: '100 0 auto', width: '150px', textAlign: 'left', paddingLeft: '24px'}}>
                  <div>Country</div>
                </div>
                <div className="rt-th -cursor-pointer" role="columnheader" tabIndex={-1} style={{flex: '100 0 auto', width: '100px'}}>
                  <div>Degree Required</div>
                </div>
                <div className="rt-th -cursor-pointer" role="columnheader" tabIndex={-1} style={{flex: '100 0 auto', width: '100px'}}>
                  <div>Visa Duration</div>
                </div>
                <div className="rt-th -cursor-pointer" role="columnheader" tabIndex={-1} style={{flex: '100 0 auto', width: '100px'}}>
                  <div>Bootstrappers</div>
                </div>
                <div className="rt-th -cursor-pointer" role="columnheader" tabIndex={-1} style={{flex: '100 0 auto', width: '100px'}}>
                  <div>Visa Fees</div>
                </div>
                <div className="rt-th -cursor-pointer" role="columnheader" tabIndex={-1} style={{flex: '100 0 auto', width: '100px'}}>
                  <div>Personal Funds</div>
                </div>
                <div className="rt-th -cursor-pointer" role="columnheader" tabIndex={-1} style={{flex: '100 0 auto', width: '100px'}}>
                  <div>Min. Investment</div>
                </div>
                <div className="rt-th -cursor-pointer" role="columnheader" tabIndex={-1} style={{flex: '100 0 auto', width: '100px'}}>
                  <div>Processing Time</div>
                </div>
              </div>
            </div>
            <div className="rt-tbody" style={{minWidth: '800px'}}>
            <AniLink fade to="/startup-visas/canada" className="country-link" duration={0.4}>
                <div className="rt-tr-group" role="rowgroup">
                    <div className="rt-tr -odd" role="row">
                    <div className="rt-td" role="gridcell" style={{flex: '100 0 auto', width: '150px'}}>🇨🇦  Canada</div>
                    <div className="rt-td" role="gridcell" style={{flex: '100 0 auto', width: '100px'}}>Not Required</div>
                    <div className="rt-td" role="gridcell" style={{flex: '100 0 auto', width: '100px'}}>Permanent</div>
                    <div className="rt-td" role="gridcell" style={{flex: '100 0 auto', width: '100px'}}>Yes</div>
                    <div className="rt-td" role="gridcell" style={{flex: '100 0 auto', width: '100px'}}>$1,180</div>
                    <div className="rt-td" role="gridcell" style={{flex: '100 0 auto', width: '100px'}}>$9,700</div>
                    <div className="rt-td" role="gridcell" style={{flex: '100 0 auto', width: '100px'}}>$0</div>
                    <div className="rt-td" role="gridcell" style={{flex: '100 0 auto', width: '100px'}}>365 days</div>
                    </div>
                </div>
              </AniLink>
              <AniLink fade to="/startup-visas/estonia" className="country-link" duration={0.4}>
                <div className="rt-tr-group" role="rowgroup">
                    <div className="rt-tr -even" role="row">
                    <div className="rt-td" role="gridcell" style={{flex: '100 0 auto', width: '150px'}}>🇪🇪  Estonia</div>
                    <div className="rt-td" role="gridcell" style={{flex: '100 0 auto', width: '100px'}}>Not Required</div>
                    <div className="rt-td" role="gridcell" style={{flex: '100 0 auto', width: '100px'}}>12 months</div>
                    <div className="rt-td" role="gridcell" style={{flex: '100 0 auto', width: '100px'}}>Yes</div>
                    <div className="rt-td" role="gridcell" style={{flex: '100 0 auto', width: '100px'}}>$88</div>
                    <div className="rt-td" role="gridcell" style={{flex: '100 0 auto', width: '100px'}}>$1,994</div>
                    <div className="rt-td" role="gridcell" style={{flex: '100 0 auto', width: '100px'}}>$0</div>
                    <div className="rt-td" role="gridcell" style={{flex: '100 0 auto', width: '100px'}}>60 days</div>
                    </div>
                </div>
                </AniLink>
                <AniLink fade to="/startup-visas/latvia" className="country-link" duration={0.4}>
                  <div className="rt-tr-group" role="rowgroup">
                    <div className="rt-tr -odd" role="row">
                    <div className="rt-td" role="gridcell" style={{flex: '100 0 auto', width: '150px'}}>🇱🇻  Latvia </div>
                    <div className="rt-td" role="gridcell" style={{flex: '100 0 auto', width: '100px'}}>Not Required</div>
                    <div className="rt-td" role="gridcell" style={{flex: '100 0 auto', width: '100px'}}>36 months</div>
                    <div className="rt-td" role="gridcell" style={{flex: '100 0 auto', width: '100px'}}>Yes</div>
                    <div className="rt-td" role="gridcell" style={{flex: '100 0 auto', width: '100px'}}>$221-443</div>
                    <div className="rt-td" role="gridcell" style={{flex: '100 0 auto', width: '100px'}}>$5,720</div>
                    <div className="rt-td" role="gridcell" style={{flex: '100 0 auto', width: '100px'}}>$0</div>
                    <div className="rt-td" role="gridcell" style={{flex: '100 0 auto', width: '100px'}}>30 days</div>
                    </div>
                </div>
              </AniLink>
              <AniLink fade to="/startup-visas/lithuania" className="country-link" duration={0.4}>
                <div className="rt-tr-group" role="rowgroup">
                    <div className="rt-tr -even" role="row">
                    <div className="rt-td" role="gridcell" style={{flex: '100 0 auto', width: '150px'}}>🇱🇹  Lithuania</div>
                    <div className="rt-td" role="gridcell" style={{flex: '100 0 auto', width: '100px'}}>Not Required</div>
                    <div className="rt-td" role="gridcell" style={{flex: '100 0 auto', width: '100px'}}>12 months</div>
                    <div className="rt-td" role="gridcell" style={{flex: '100 0 auto', width: '100px'}}>Yes</div>
                    <div className="rt-td" role="gridcell" style={{flex: '100 0 auto', width: '100px'}}>$168</div>
                    <div className="rt-td" role="gridcell" style={{flex: '100 0 auto', width: '100px'}}>$8,173</div>
                    <div className="rt-td" role="gridcell" style={{flex: '100 0 auto', width: '100px'}}>$0</div>
                    <div className="rt-td" role="gridcell" style={{flex: '100 0 auto', width: '100px'}}>30 days</div>
                    </div>
                </div>
              </AniLink>
              <AniLink fade to="/startup-visas/finland" className="country-link" duration={0.4}>
                <div className="rt-tr-group" role="rowgroup">
                    <div className="rt-tr -odd" role="row">
                    <div className="rt-td" role="gridcell" style={{flex: '100 0 auto', width: '150px'}}>🇫🇮  Finland</div>
                    <div className="rt-td" role="gridcell" style={{flex: '100 0 auto', width: '100px'}}>Not Required</div>
                    <div className="rt-td" role="gridcell" style={{flex: '100 0 auto', width: '100px'}}>24 months</div>
                    <div className="rt-td" role="gridcell" style={{flex: '100 0 auto', width: '100px'}}>Yes</div>
                    <div className="rt-td" role="gridcell" style={{flex: '100 0 auto', width: '100px'}}>$390</div>
                    <div className="rt-td" role="gridcell" style={{flex: '100 0 auto', width: '100px'}}>$26,760</div>
                    <div className="rt-td" role="gridcell" style={{flex: '100 0 auto', width: '100px'}}>$0</div>
                    <div className="rt-td" role="gridcell" style={{flex: '100 0 auto', width: '100px'}}>90 days</div>
                    </div>
                </div>
              </AniLink>
              <AniLink fade to="/startup-visas/denmark" className="country-link" duration={0.4}>
                <div className="rt-tr-group" role="rowgroup">
                    <div className="rt-tr -even" role="row">
                    <div className="rt-td" role="gridcell" style={{flex: '100 0 auto', width: '150px'}}>🇩🇰  Denmark</div>
                    <div className="rt-td" role="gridcell" style={{flex: '100 0 auto', width: '100px'}}>Not Required</div>
                    <div className="rt-td" role="gridcell" style={{flex: '100 0 auto', width: '100px'}}>24 months</div>
                    <div className="rt-td" role="gridcell" style={{flex: '100 0 auto', width: '100px'}}>Yes</div>
                    <div className="rt-td" role="gridcell" style={{flex: '100 0 auto', width: '100px'}}>$282</div>
                    <div className="rt-td" role="gridcell" style={{flex: '100 0 auto', width: '100px'}}>$20,485</div>
                    <div className="rt-td" role="gridcell" style={{flex: '100 0 auto', width: '100px'}}>$0</div>
                    <div className="rt-td" role="gridcell" style={{flex: '100 0 auto', width: '100px'}}>45 days</div>
                    </div>
                </div>
              </AniLink>
              <AniLink fade to="/startup-visas/netherlands" className="country-link" duration={0.4}>
                <div className="rt-tr-group" role="rowgroup">
                    <div className="rt-tr -odd" role="row">
                    <div className="rt-td" role="gridcell" style={{flex: '100 0 auto', width: '150px'}}>🇳🇱  Netherlands</div>
                    <div className="rt-td" role="gridcell" style={{flex: '100 0 auto', width: '100px'}}>Not Required</div>
                    <div className="rt-td" role="gridcell" style={{flex: '100 0 auto', width: '100px'}}>12 months</div>
                    <div className="rt-td" role="gridcell" style={{flex: '100 0 auto', width: '100px'}}>Yes</div>
                    <div className="rt-td" role="gridcell" style={{flex: '100 0 auto', width: '100px'}}>$252</div>
                    <div className="rt-td" role="gridcell" style={{flex: '100 0 auto', width: '100px'}}>$15,224</div>
                    <div className="rt-td" role="gridcell" style={{flex: '100 0 auto', width: '100px'}}>$0</div>
                    <div className="rt-td" role="gridcell" style={{flex: '100 0 auto', width: '100px'}}>30 days</div>
                    </div>
                </div>
              </AniLink>
            </div>
          </div>
        </div>
      );}

  export default Table
