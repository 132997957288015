import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"

const MobileVisas = ({ children }) => {
  return (
      <div className="mobile-list">
             <AniLink fade to="/startup-visas/canada" duration={0.4}>
            <div className="item-container">
            <div className="item-header">
            <div className="country-icon-container">
          <span role="img" aria-label="Canada">🇨🇦</span>
          </div>        
            Canada
                <div className="item-arrow">
                →
                </div>
            </div>
            </div>
            </AniLink>
            <AniLink fade to="/startup-visas/estonia" duration={0.4}>
            <div className="item-container">
            <div className="item-header">
            <div className="country-icon-container">
          <span role="img" aria-label="Estonia">🇪🇪</span>
          </div>        
            Estonia
                <div className="item-arrow">
                →
                </div>
            </div>
            </div>
            </AniLink>
            <AniLink fade to="/startup-visas/lithuania" duration={0.4}>
            <div className="item-container">
            <div className="item-header">
            <div className="country-icon-container">
          <span role="img" aria-label="Lithuania">🇱🇹</span>
          </div>        
            Lithuania
                <div className="item-arrow">
                →
                </div>
            </div>
            </div>
            </AniLink>
            <AniLink fade to="/startup-visas/latvia" duration={0.4}>
            <div className="item-container">
            <div className="item-header">
            <div className="country-icon-container">
          <span role="img" aria-label="Latvia">🇱🇻</span>
          </div>        
            Latvia
                <div className="item-arrow">
                →
                </div>
            </div>
            </div>
            </AniLink>
            <AniLink fade to="/startup-visas/denmark" duration={0.4}>
            <div className="item-container">
            <div className="item-header">
            <div className="country-icon-container">
          <span role="img" aria-label="Denmark">🇩🇰</span>
          </div>        
            Denmark
                <div className="item-arrow">
                →
                </div>
            </div>
            </div>
            </AniLink>
            <AniLink fade to="/startup-visas/netherlands" duration={0.4}>
            <div className="item-container">
            <div className="item-header">
            <div className="country-icon-container">
          <span role="img" aria-label="Netherlands">🇳🇱</span>
          </div>        
            Netherlands
                <div className="item-arrow">
                →
                </div>
            </div>
            </div>
            </AniLink>
            <AniLink fade to="/startup-visas/finland" duration={0.4}>
            <div className="item-container">
            <div className="item-header">
            <div className="country-icon-container">
          <span role="img" aria-label="Finland">🇫🇮</span>
          </div>        
            Finland
                <div className="item-arrow">
                →
                </div>
            </div>
            </div>
            </AniLink>
        </div>
  )}
  
  export default MobileVisas
